<template>
  <Form 
    @submit="saveUser"
    :validation-schema="schema"
    class="relative"
    >
    <button v-if="!editable"
      type="button"
      class="text-th-secondary hover:text-th-darker font-bold text-left absolute right-0 -top-6"
      @click="editable = true"
    >
      <faIcon
      icon="pen"
      type="fas"
      class="text-blue-600 text-2xl hover:text-blue-500"
      title="Editar"
      >
      </faIcon>
    </button>
    <div class="grid grid-cols-1 sm:grid-cols-2 gap-6 mt-4 text-gray-900">
      <div class="relative text-left">
        <label for="name" class="sm:text-xl" > Mi nombre: </label>
        <Field
          name="name"
          class="form-input w-full mt-2 rounded-md sm:text-xl border-gray-500"
          v-model="user.nombre"
          :disabled="!editable"
        />
        <ErrorMessage name="name" class="absolute text-sm text-red-600 inset-x-0 -bottom-5"/>
      </div>
      <div class="relative text-left">
        <label for="email" class="sm:text-xl"> Correo electrónico: </label>
        <Field
          type="email"
          name="email"
          class="form-input w-full mt-2 rounded-md sm:text-xl border-gray-500"
          v-model="user.email"
          :disabled="!editable"
        />
        <ErrorMessage name="email" class="absolute text-sm text-red-600 inset-x-0 -bottom-5"/>
      </div>
    </div>
    <div class="flex justify-center mt-4">
      <BaseBtn v-if="editable"
        type="submit"
        text="Guardar cambios"
        btnClass="px-6 py-4 bg-green-700 text-white rounded-md hover:bg-green-600 focus:outline-none mt-8"
      />
      
    </div>
  </Form>
</template>
<script>
import AuthService from "@/services/AuthService"
import BaseBtn from "@/components/BaseBtn"
import { Field, Form, ErrorMessage } from "vee-validate"
import { object, string } from "yup"
import { getError } from "@/utils/helpers"

export default {
    name: "UserProfile",
    setup() {
      const schema = object({
        name: string().required("El nombre es obligatorio."),
        email: string().required("El correo es obligatorio").email("Este no es un correo válido."),
      })

      return {
        schema
      }
    },
    data() {
      return {
        user :{
          nombre: "",
          email: "",
        },
        editable: false
      }
    },
    methods: {
      saveUser() {
        let payload = this.user
        AuthService.updateUser(payload)
          .then(() => this.$store.dispatch("auth/getAuthUser"))
          .then(() => {
            this.emitter.emit("notification-set", {
              type: 'success',
              title: "Perfil actualizado",
              text: "Se han actualizado los datos de tu perfil.",
              time: 10000,
              group: 'notifications'
            })
            this.editable = false
          })
          .catch((error) => console.log(getError(error)))
      }
    },
    mounted() {
      let user = this.$store.getters["auth/authUser"]
      this.user.nombre = user.name
      this.user.email = user.email
    },
    components: {
      BaseBtn,
      Field,
      Form,
      ErrorMessage
    }
}
</script>